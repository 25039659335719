
'use strict';

jQuery( function() {

  jQuery( '.select-category' ).click( function() {
    jQuery( '.categories-wrap' ).slideToggle();
  });

});
