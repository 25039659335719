
'use strict';

jQuery( () => {

  // **************** RESIZE EVENTS **************** //

  /*The purpose of this is to prevent a resize function from being called
   when the mobile / tablet scroll bar disappears/appears on scroll */

  let localDeviceWidth = document.documentElement.clientWidth,
      localDeviceHeight = document.documentElement.clientHeight;

  jQuery( window ).resize( () => {

    let resizedDeviceWidth = jQuery( window ).innerWidth(),
        resizedDeviceHeight = jQuery( window ).innerHeight();

    // ============ WIDTH ONLY RESIZE EVENTS ============ //

    if ( localDeviceWidth !== resizedDeviceWidth ) {

      console.log( 'device width has changed on resize' );

      // Update the local device width with the new size after resize is finished
      localDeviceWidth = resizedDeviceWidth;

    }

    // ============ HEIGHT ONLY RESIZE EVENTS ============ //

    if ( localDeviceHeight !== resizedDeviceHeight ) {

      console.log( 'device height has changed on resize' );

      // Update the local device height with the new size after resize is finished
      localDeviceHeight = resizedDeviceHeight;

    }

  });

}); // JQuery wrapper
