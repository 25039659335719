'use strict';

require( 'slick-carousel' );

jQuery( document ).ready( function() {

  const initSlick = function() {

    let slideNo;
    const chrMobileBreakpoint = 767;
    const chrTabletBreakpoint = 1199;
    const SlidesOnMobile = 2;
    const SlidesOnTablet = 3;
    const SlidesOnDesktop = 5;

    if ( window.matchMedia( `(max-width: ${chrMobileBreakpoint}px)` ).matches ) {
      slideNo = SlidesOnMobile;

    } else if ( window.matchMedia( `(max-width: ${chrTabletBreakpoint}px)` ).matches ) {
      slideNo = SlidesOnTablet;

    } else {
      slideNo = SlidesOnDesktop;
    }

    jQuery( '.clients-module__slider.slick-slider' ).slick( 'unslick' );

    jQuery( '.clients-module__slider' ).slick({
      slidesToShow: slideNo,

      slidesToScroll: 1,

      autoplay: true,

      prevArrow: '<p class="slick-prev" aria-label="Previous"></p>',

      nextArrow: '<p class="slick-next" aria-label="Next"></p>',

      autoplaySpeed: 5000

    });

  };

  jQuery( window ).resize( initSlick );

  initSlick();

});
