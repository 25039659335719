/**
 * Accordion module
 *
 * Chromatix DJ 28/11/2017
 */
'use strict';

const chrPhoneBreakpoint = 767;
const chrAccordionScrolloffset = 100;
const chrResponsiveAccordionOffset = 80;

/**
 * Sets the window hash to the title
 *
 * @param {string} titleToHash - the data attribute 'title' of the item.
 *
 * @returns {undefined}
 */
function setWindowHash( titleToHash ) {
  const loc = '#' + titleToHash;

  // Set new hash state while maintaining our scroll position, with browser-compat
  // HT: http://stackoverflow.com/a/5298684/1982136
  // & comments on http://stackoverflow.com/a/14690177/1982136
  if ( 'pushState' in history ) {

    // The new way, using pushState
    history.replaceState( '', titleToHash, loc );

  } else {

    // The old way, for browser-compat -> IE9

    // grab the scroll position, because it will jump to the top
    const scrollV = jQuery( 'html' ).scrollTop();
    const scrollH = jQuery( 'html' ).scrollLeft();
    const negOne = -1;

    // Note: blank will set to null in IE9
    if ( negOne === loc.indexOf( '#' ) ) {
      window.location.hash = 'tabs';
    } else {
      window.location = loc;
    }

    // Restore the original scroll position
    jQuery( 'html' ).scrollTop( scrollV ).scrollLeft( scrollH );

  }
}

/**
 * Scroll to accordion module with offset
 *
 * @param {object} item - the accordion item
 * @param {number} scrollOffset - scroll offset to the top of accordion item.
 *
 * @returns {undefined}
 */
function scrollToAccordion( item, scrollOffset ) {
  const fifty = 50;
  const animationDuration = 600;

  const itemTop = item.offset().top;
  const currentScrollPos = jQuery( 'html' ).scrollTop();

  const newScrollPos = itemTop - scrollOffset;

  const scrollPosDifference = Math.abs( currentScrollPos - newScrollPos );

  if ( fifty < scrollPosDifference ) {

    // Only complete the scroll if it's substantial enough
    jQuery( 'html, body' ).animate({ scrollTop: newScrollPos }, animationDuration );
  }
}

/**
 *
 *
 * @returns {undefined}
 */
function openWindowHash() {
  const zeroTimeout = 0;
  const scrollTimeout = 200;

  const hashItem = jQuery( '.item_title[data-title=\'' +
                 window.location.hash.replace( '#', '' ) + '\']' );

  if ( hashItem.length ) {
    hashItem.addClass( 'opened' );
    hashItem.next().show();

    setTimeout( function() {
      setTimeout( function() {
        scrollToAccordion( hashItem, chrAccordionScrolloffset );
      }, scrollTimeout );
    }, zeroTimeout );
  }
}

/**
 * Scroll the screen up to the specific
 *
 * @param {object} clickedItem - the item to scroll to
 *
 * @returns {undefined}
 */
function scrollUpOnResponsive( clickedItem ) {
  const timeOut = 300;
  const animationDuration = 200;

  setTimeout( function() {
    jQuery( 'body,html' ).animate({
      scrollTop: jQuery( clickedItem ).offset().top - chrResponsiveAccordionOffset },
    animationDuration );
  }, timeOut );
}

/**
 * Opens the specific Accordion item that was clicked or opened via window hash.
 *
 * @param {object} item - the clicked or hashed accordion item.
 *
 * @returns {undefined}
 */
function openAccordionItem( item ) {
  const index = item.attr( 'data-acc' );
  const wrap = jQuery( '.accordion_wrap[data-acc=' + index + ']' );
  const allContent = jQuery( '.item_content[data-acc=' + index + ']' );
  const allTitles = jQuery( '.item_title[data-acc=' + index + ']' );

  let clickedItem = false;

  if ( item.hasClass( 'opened' ) ) {
    item.removeClass( 'opened' );
    item.next( '.item_content' ).slideUp();
  } else {
    if ( wrap.hasClass( 'collapsable' ) ) {
      allContent.slideUp( );
      allTitles.removeClass( 'opened' );
    }

    clickedItem = item.addClass( 'opened' );
    item.next( '.item_content' ).slideDown();

    // Responsive scroll up
    if ( window.matchMedia( `(max-width: ${chrPhoneBreakpoint}px)` ).matches && clickedItem ) {
      scrollUpOnResponsive( clickedItem );
    }
  }
}

/**
 * Setup of events, classes and data attributes for the Accordion(s).
 *
 * @returns {undefined}
 */
function accordionInit() {

  let accordionNum = 0; // Initialize our accordion ID variable
  // Open the first item if we have chosen to open it in the back-end
  const firstOpen = jQuery( '.accordion_wrap.first_open' );

  jQuery( '.faq-module' ).each( function() { // Loop through each accordion on the page

    // set ID to refer to later on
    jQuery( this ).find( '.accordion_wrap' ).attr( 'data-acc', accordionNum );
    jQuery( this ).find( '.item_title' ).attr( 'data-acc', accordionNum );
    jQuery( this ).find( '.item_content' ).attr( 'data-acc', accordionNum );

    // Increment z for next accordion module
    accordionNum++;
  });

  // Open first
  if ( firstOpen.length && ! window.location.hash ) {
    firstOpen.each( function() {
      jQuery( this ).find( '.item_title' ).first().addClass( 'opened' );
      jQuery( this ).find( '.item_content' ).first().show();
    });
  }

  // Click event
  jQuery( '.item_title' ).click( function() {
    const thisItem = jQuery( this );
    const title = thisItem.attr( 'data-title' );

    if ( ! thisItem.hasClass( 'active' ) ) {
      openAccordionItem( thisItem );
    }

    setWindowHash( title );

  });

  // Check if window has hash to open certain tab
  if ( window.location.hash ) {
    openWindowHash();
  }
}

/**
 * Initialize the Accordion if it is on the page.
 */
jQuery( document ).ready( function( ) {
  if ( jQuery( '.faq-module' ).length ) {
    accordionInit();
  }
});
