/*
 * Main JS file for JS imports.
 *
 * @author Amrit Cheema <amrit.cheema@chromatix.com.au>
 */

'use strict';

require( './layout/responsive' );

require( './components/fixed-header' );
require( './components/contact-form' );
require( './components/blog' );
require( './components/map' );

require( '../../modules/**/*.js', {mode: 'expand'});

// Uncomment to enable browser detection tools on this site.
// For emergency use only. This should not be used unless there is no other way!
//require( './utilities/browser-detect' );
