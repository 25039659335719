
//Js for jumping image issue in IE11

'use strict';

if ( navigator.userAgent.match( /Trident\/7\./ ) ) {
  document.body.addEventListener( 'mousewheel', function() {
    const wd = event.wheelDelta;
    const csp = window.pageYOffset;
    const top = 0;
    event.preventDefault();
    window.scrollTo( top, csp - wd );
  });
}
