'use strict';

const tabletBreakpoint = 1199;

jQuery( document ).ready( function() {

  let htmlCustom = '<span class="menu_icon"></span>';

  //Scroll down icon on banner

  let scrollIcon = function() {

    jQuery( window ).on( 'scroll', function() {
      const limitTop = 30;
      if ( jQuery( this ).scrollTop() > limitTop ) {
        jQuery( '.scroll-downs' ).css( 'display', 'none' );
      }

      if ( jQuery( this ).scrollTop() < limitTop ) {
        jQuery( '.scroll-downs' ).css( 'display', 'flex' );
      }

    });

  };
  jQuery( window ).resize( scrollIcon );
  scrollIcon();

  /*Handle some scroll events*/
  jQuery( window ).scroll( function() {

    const scrollTop = jQuery( window ).scrollTop();
    const absoluteTop = 0;

    if ( jQuery( 'body.home' ).length ) {

      if ( scrollTop <= absoluteTop ) {

        jQuery( '.masthead' ).addClass( 'home' );

      } else {

        jQuery( '.masthead' ).removeClass( 'home' );

      }

    }

  }).scroll();

  //Mobile side menu
  jQuery( '.menu_toggle' ).click( function() {
    jQuery( 'body' ).toggleClass( 'menu_active' );

    if ( jQuery( 'body' ).hasClass( 'menu_active' ) ) {
      jQuery( '.menu_toggle' ).html( '&#x2716;' );
    } else {
      jQuery( '.menu_toggle' ).html( '&#9776;' );
    }

  });
  jQuery( '.overlay_toggle_menu' ).click( function() {
    jQuery( 'body' ).removeClass( 'menu_active' );
    jQuery ( '.sub-menu' ).removeClass( 'open' );
    jQuery( '.menu_toggle' ).html( '&#9776;' );
  });

  //Mobile side menu end

  // Open Sub Menu Onclick For responsive
  if ( window.matchMedia( '( max-width:' + tabletBreakpoint + 'px )' ).matches ) {

    // Adding html to href
    if ( jQuery( '.menu' ).has( 'li.menu-item-has-children' ) ) {
      jQuery( 'li.menu-item-has-children > a' ).after( jQuery( htmlCustom ) );
    }

    // Adding html to href end

    // Click Event for sub menu

    jQuery( '.menu-item-has-children > .menu_icon' ).click( function( event ) {
      if ( jQuery( event.target ).parent().siblings().find( '.sub-menu' ) .hasClass( 'open' ) ) {
        jQuery ( '.sub-menu' ).removeClass( 'open' );
      }

      jQuery ( event.target ).parent().find( '.sub-menu' ).toggleClass( 'open' );
    });

    // Click Event for sub menu end

  }

  // Open Sub Menu Onclick For responsive end

  // Checking href is empty or not

  jQuery( '.menu-item-has-children > a' ).click( function( event ) {
    if ( '' === jQuery( this ).attr( 'href' ) || '#' === jQuery( this ).attr( 'href' ) ) {
      event.preventDefault();
    }
  });

  jQuery( '.bg_alternate:odd' ).css( 'background-color', '#f3f3f3' );
});
