'use strict';

require( 'slick-carousel' );

jQuery( document ).ready( function() {

  jQuery( '.testimonails-module__slider' ).slick({
    slidesToShow: 1,

    slidesToScroll: 1,

    autoplay: true,

    autoplaySpeed: 6000,

    fade: true,

    prevArrow: '',

    nextArrow: ''
  });

});
