
'use strict';
require( 'slick-carousel' );

const chrPhoneBreakpoint = 767;

jQuery( function() {

  const initSlick = function() {

    jQuery( '.icon-column-module__column-wrapper.slick-slider' ).slick( 'unslick' );

    if ( window.matchMedia( `(max-width: ${chrPhoneBreakpoint}px)` ).matches ) {

      jQuery( '.icon-column-module__column-wrapper' ).slick({
        slidesToShow: 1,

        slidesToScroll: 1,

        autoplay: true,

        autoplaySpeed: 2000,

        dots: true,

        infinite: true,

        cssEase: 'linear',

        prevArrow: false,

        nextArrow: false
      });

    }
  };

  jQuery( window ).resize( initSlick );
  initSlick();

});
