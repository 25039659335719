/**
 * Custom scripting for Contact Form 7 forms on Chromatix Base IPX Theme.
 *
 * @author Amrit Cheema <amrit.cheema@chromatix.com.au>
 */

'use strict';

jQuery( function() {

  // Remove success/invalid messages when the contact form is clicked/tapped.
  jQuery( 'form.wpcf7-form' ).click( function() {

    jQuery( this )
      .removeClass( 'invalid' )
      .find( '.wpcf7-response-output' )
      .fadeOut( 'fast' );

  });

  // Hook into the CF7 submit action to blur focus from all form elements on submission.
  document.addEventListener( 'wpcf7submit', function() {
    jQuery( 'form.wpcf7-form input' ).blur();
  });

  // Scroll down to the contact form when the contact menu item is clicked.
  jQuery( '.menu .contact-menu-link' ).click( function() {

    jQuery( 'html, body' ).animate(
      {
        scrollTop: jQuery( '#contact_form' ).offset().top
      },
      'slow'
    );

    // Remove mobile side menu.
    if ( jQuery( 'body' ).hasClass( 'menu_active' ) ) {
      jQuery( 'body' ).removeClass( 'menu_active' );
      jQuery( '.menu_toggle' ).html( '&#9776;' );
    }

  }); // Contact menu link click.
}); // JQuery wrapper.
