
/* global google */

'use strict';

/**
 * This function will add a marker to the selected Google Map.
 *
 * @param {int} $marker jQuery element.
 * @param {int} map     Google Map object.
 * @return {int} Marker.
 */
function addMarker( $marker, map ) {

  var latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );

  // Create info window.
  var infowindow = new google.maps.InfoWindow({
    content: $marker.html()
  });

  // Create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map:      map
  });

  // Add to array.
  map.markers.push( marker );

  // If marker contains HTML, add it to an infoWindow.
  if ( $marker.html() ) {

    // Show info window when marker is clicked.
    google.maps.event.addListener( marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

} // Function addMarker.

/**
 * This function will center the map, showing all markers attached to this map.
 *
 * @param {string} map Google Map object.
 * @return {int} Object.
 */
function centerMap( map ) {

  var bounds = new google.maps.LatLngBounds();
  var digitOne = 1;
  var digitSixteen = 16;

  // Loop through all markers and create bounds.
  jQuery.each( map.markers, function( inc, marker ) {

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // Only 1 marker?
  if ( digitOne === map.markers.length ) {

    // Set center of map.
    map.setCenter( bounds.getCenter() );
    map.setZoom( digitSixteen );
    return;

  }

  // Otherwise, fit to bounds.
  map.fitBounds( bounds );

} // Function centerMap.

/**
 * NewMap.
 *
 * @param {int} $el First variable.
 * @returns {int} Newmap.
 */
function newMap( $el ) {

  var digitZero = 0;
  var $markers = $el.find( '.marker' );

  var args = {
    zoom:      16,
    center:    new google.maps.LatLng( digitZero, digitZero ),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };

  // Create map.
  var map = new google.maps.Map( $el[digitZero], args );

  // Add a markers reference.
  map.markers = [];

  // Add markers.
  $markers.each( function() {
    addMarker( $( this ), map );
  });

  centerMap( map );

  return map;

} // Function newMap.

/**
 * This function will render each map when the document is ready (page has loaded)
 *
 * @returns {void}
 */

jQuery( function() {
  jQuery( '.acf-map' ).each( function() {

    // Create map.
    newMap( jQuery( this ) );

  });
});
